import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { GlobalContext, showToast } from "../globalContext";
import { getAdminAirports } from '../service/AdminApi/AdminApi';
import { editPartner } from '../service/AdminApi/partnerApi';
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../view-partner-components/QuillToolbar";
import 'react-quill/dist/quill.snow.css';
import Spinner from '../components/Spinner';


const EditPartnerModal = ({ onCLose, getData, partnerData }) => {

    const { dispatch: globalDispatch } = React.useContext(GlobalContext);
    const [airportBox, setAirportBox] = useState([])
    const [emailNote, setEmailNote] = useState('');
    const [airportLoader, setAirportLoader] = useState(false)





    const schema = yup
        .object({
            name: yup.string().required(),
            airport_id: yup.number().required(),
            commission: yup.number().max(100, "Value must not exceed 100"),
            email: yup.string().email().required(),
            contact_email: yup.string().email().required(),
            phone_number: yup
                .string()
                .required("Phone number is required")
                .min(10, "Phone number must be 10 digits")
                .max(10, "Phone number must be 10 digits"),
            first_name: yup
                .string()
                .required()
                .matches(
                    /^[A-Za-z\s]+$/,
                    "First name must contain only letters and spaces"
                ),
            last_name: yup
                .string()
                .required()
                .matches(
                    /^[A-Za-z\s]+$/,
                    "Last name must contain only letters and spaces"
                ),
            address: yup.string().required(),
            map_link: yup.string().url().required(),
            // description: yup.string().required(),
            // information: yup.string().required(),
            // email_msg: yup.string().required(),
        })



    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });


    const getAirport = async () => {
        setAirportLoader(true)
        const airportData = await getAdminAirports();
        setValue("airport_id", partnerData?.airport_id);
        setTimeout(() => {
            setAirportBox(airportData.data);
            setValue("airport_id", partnerData?.airport_id);
            setAirportLoader(false)
        }, 50);


    };


    const onSubmit = async (data) => {
        editPartner(data, emailNote, (success, resp) => {
            if (success) {
                onCLose()
                getData()
                showToast(globalDispatch, "Partner Details are Updated");
            } else {
                alert('something went wrong!')
            }

        });
    };


    useEffect(function () {

        getAirport()
        setValue("name", partnerData?.name);
        setValue("airport_id", partnerData?.airport_id);
        setValue("commission", partnerData?.commission);
        setValue("email", partnerData?.email);
        setValue("contact_email", partnerData?.contact_email);
        setValue("phone_number", partnerData?.phone_number);
        setValue("first_name", partnerData?.first_name);
        setValue("last_name", partnerData?.last_name);
        setValue("address", partnerData?.address);
        setValue("map_link", partnerData?.map_link);
        setValue("id", partnerData?.id);
        // setValue('email_msg', partnerData.email_msg)
        setEmailNote(partnerData.email_msg)

    }, []);




    return (
        <div className="modal-holder">
            <div className="modal-outer" onClick={() => { onCLose() }}></div>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[900px] w-full mx-auto">
                <div className="flex justify-between items-center">
                    <h4 className='md:text-[24px] text-[18px] text-[#2B2B2B] font-[400] mb-4'>Edit <b>{partnerData?.name}</b></h4>
                    <button
                        type='button'
                        onClick={() => { onCLose() }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 2.5C8.0875 2.5 2.5 8.0875 2.5 15C2.5 21.9125 8.0875 27.5 15 27.5C21.9125 27.5 27.5 21.9125 27.5 15C27.5 8.0875 21.9125 2.5 15 2.5ZM20.375 20.375C20.2594 20.4909 20.122 20.5828 19.9708 20.6455C19.8196 20.7083 19.6575 20.7406 19.4937 20.7406C19.33 20.7406 19.1679 20.7083 19.0167 20.6455C18.8655 20.5828 18.7281 20.4909 18.6125 20.375L15 16.7625L11.3875 20.375C11.1538 20.6087 10.8368 20.74 10.5062 20.74C10.1757 20.74 9.85872 20.6087 9.625 20.375C9.39128 20.1413 9.25997 19.8243 9.25997 19.4937C9.25997 19.3301 9.29221 19.168 9.35484 19.0168C9.41747 18.8656 9.50927 18.7282 9.625 18.6125L13.2375 15L9.625 11.3875C9.39128 11.1538 9.25997 10.8368 9.25997 10.5062C9.25997 10.1757 9.39128 9.85872 9.625 9.625C9.85872 9.39128 10.1757 9.25997 10.5062 9.25997C10.8368 9.25997 11.1538 9.39128 11.3875 9.625L15 13.2375L18.6125 9.625C18.7282 9.50927 18.8656 9.41747 19.0168 9.35484C19.168 9.29221 19.3301 9.25997 19.4937 9.25997C19.6574 9.25997 19.8195 9.29221 19.9707 9.35484C20.1219 9.41747 20.2593 9.50927 20.375 9.625C20.4907 9.74073 20.5825 9.87812 20.6452 10.0293C20.7078 10.1805 20.74 10.3426 20.74 10.5062C20.74 10.6699 20.7078 10.832 20.6452 10.9832C20.5825 11.1344 20.4907 11.2718 20.375 11.3875L16.7625 15L20.375 18.6125C20.85 19.0875 20.85 19.8875 20.375 20.375Z" fill="#B8B6B6" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-col gap-4 add-partner-fields-holder">
                    <div className="grid md:grid-cols-3 grid-cols-2 gap-4">
                        <div className="popup-field-box">
                            <input
                                {...register("id")}
                                type='hidden' />
                            <label>Parking lot name</label>
                            <input
                                {...register("name")}
                                type='text' placeholder='Enter Parking Lot name' />
                        </div>
                        <div className="popup-field-box">
                            <label>Select Airport</label>
                            {airportLoader ?
                                <div className="flex mt-2 items-center justify-center">
                                    <Spinner />
                                </div>
                                :
                                <select
                                    {...register("airport_id")}
                                >
                                    {airportBox.map((item) => (
                                        <option value={item?.id}>{item?.name}</option>
                                    ))}
                                </select>
                            }
                        </div>
                        <div className="popup-field-box">
                            <label>Commission</label>
                            <input
                                {...register("commission")}
                                type='text' placeholder='Commission %' />
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
                        <div className="popup-field-box">
                            <label>Confirmation Email</label>
                            <input
                                {...register("email")}
                                type='email' placeholder='Enter Lot Email Address' />
                        </div>
                        <div className="popup-field-box">
                            <label>Contact Email</label>
                            <input
                                {...register("contact_email")}
                                type='email' placeholder='Enter Contact Email Address' />
                        </div>
                        <div className="popup-field-box">
                            <label>Phone Number</label>
                            <input
                                {...register("phone_number")}
                                type='text' placeholder='Enter Lot Phone Number' />
                        </div>
                        <div className="popup-field-box">
                            <label>Lot Owner First Name</label>
                            <input
                                {...register("first_name")}
                                type='text' placeholder='First Name' />
                        </div>
                        <div className="popup-field-box">
                            <label>Lot Owner Last Name</label>
                            <input
                                {...register("last_name")}
                                type='text' placeholder='Last Name' />
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                        <div className="popup-field-box">
                            <label> Address</label>
                            <input
                                {...register("address")}
                                type='text' placeholder='Enter Lot Email Address' />
                        </div>
                        <div className="popup-field-box">
                            <label>Google Map Diretion Link</label>
                            <input
                                {...register("map_link")}
                                type='text' placeholder='Past Link here' />
                        </div>
                    </div>

                    <div className="grid md:grid-cols-1 grid-cols-1 gap-4">
                        <div className="popup-field-box">
                            <label> Email Note</label>
                            {/* <textarea
                                {...register("email_msg")}
                                type='text' placeholder='Enter Lot Email Address'
                            ></textarea> */}

                            <EditorToolbar />
                            <ReactQuill
                                id="emailNote"
                                value={emailNote}
                                //  onChange={(e) => setEmailNote(e.target.value)}
                                onChange={setEmailNote}
                                modules={modules}
                                formats={formats}
                            />
                        </div>

                    </div>


                </div>





                <button


                    className=' bg-[#2EABE3] rounded-[10px] text-center py-3 text-[white] w-full text-[16px] mt-4' type='submit'>Save Changes</button>
            </form >
        </div >
    )
}

export default EditPartnerModal
