import React from "react";

import { AuthContext } from "../AuthContext";
import { GlobalContext } from "../globalContext";

const PageTitle = () => {
  const { user } = React.useContext(AuthContext).state;
  const { state } = React.useContext(GlobalContext);

  const token = localStorage.getItem("AdminInfo")

  return (
    <div className="flex gap-1 flex-col page-title">
      <p className="text-[#797979] md:text-[16px] text-[12px] font-[400]">
        Hi {user?.first_name}
      </p>
      <h4 className="md:text-[25px] text-[18px] text-[#000000] font-[500]">
        {state.pageName}
      </h4>
    </div>
  );
};

export default PageTitle;
