import React from 'react'
import { useState } from 'react'
import { useCallback } from "react";
import CancleReservation from './CancleReservation';
import moment from 'moment';

import { Reservation } from '../models/types';
import { DATE_FORMAT } from '../utils/utils';
import { getAdminPartners } from "../service/AdminApi/partnerApi";
import Spinner from '../components/Spinner';

type RevervationListCardProps = {
    data: Reservation
    partnerId?: string
    onRefresh?: () => void
    noOption?: boolean
    index?: number

}

const RevervationListCard = (props: RevervationListCardProps) => {
    const { data, partnerId, onRefresh, index, noOption } = props

    const [showCancel, setShowCancel] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [partnerBox, setPartnerBox] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const onCloseDeleteModal = useCallback(() => {
        setShowDeleteModal(false);
        setShowOptions(false)
    }, [showDeleteModal]);

    const formattedDate = moment(props.data.created_at).format("MM/DD/YYYY"); // Use props.data



    // const getData = async () => {
    //     try {
    //         const partnerData = await getAdminPartners();
    //         setPartnerBox(partnerData.data);
    //     } catch (error) {
    //         setPartnerBox([]);
    //     }
    // };
    // React.useEffect(() => {
    //     getData();
    // }, []);

    return (
        <>
            {showDeleteModal &&
                <CancleReservation onDeleteSuccess={() => {
                    onRefresh && onRefresh()
                }} data={data} onCloseModal={onCloseDeleteModal} />
            }


            <div className="relative">
                <div className="flex reservation-list-holder items-center p-list-holder justify-between gap-10">
                    <div className="p-list flex items-center gap-3">
                        <p> {index}</p>
                        <div className="flex flex-col">
                            <h4 className='text-[#202020] text-[20px] flex items-center gap-2'>#PM{data.id} <span className={`text-[13px] uppercase py-0 rounded-[10px] px-2 ${data.status == "paid" ? 'bg-[green] text-white' : data.status == "cancel" ? 'bg-[red] text-white' : 'bg-[black] text-white'}`}>{data.status}</span></h4>
                            <p className='text-[#1C1C1C] text-[16px] flex items-center gap-2 ]'> <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2115_13877" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="16"><path d="M5.99984 14.6673C5.99984 14.6673 11.3332 10.6673 11.3332 6.33398C11.3332 3.57265 8.9455 1.33398 5.99984 1.33398C3.05417 1.33398 0.666504 3.57265 0.666504 6.33398C0.666504 10.6673 5.99984 14.6673 5.99984 14.6673Z" fill="white" stroke="white" stroke-linejoin="round"></path><path d="M5 4.66797V10.0013" stroke="black" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5 4.66797H7C7.35362 4.66797 7.69276 4.80844 7.94281 5.05849C8.19286 5.30854 8.33333 5.64768 8.33333 6.0013C8.33333 6.35492 8.19286 6.69406 7.94281 6.94411C7.69276 7.19416 7.35362 7.33464 7 7.33464H5V4.66797Z" fill="black" stroke="black" stroke-linejoin="round"></path></mask><g mask="url(#mask0_2115_13877)"><path d="M-2 0H14V16H-2V0Z" fill="#2EABE3"></path></g></svg>
                                {data?.partner?.name}
                            </p>
                        </div>
                    </div>
                    <div className="p-list">
                        <div className="flex items-center gap-2">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6362 4C13.6971 4 14.7145 4.42143 15.4647 5.17157C16.2148 5.92172 16.6362 6.93913 16.6362 8C16.6362 9.06087 16.2148 10.0783 15.4647 10.8284C14.7145 11.5786 13.6971 12 12.6362 12C11.5754 12 10.5579 11.5786 9.8078 10.8284C9.05766 10.0783 8.63623 9.06087 8.63623 8C8.63623 6.93913 9.05766 5.92172 9.8078 5.17157C10.5579 4.42143 11.5754 4 12.6362 4ZM12.6362 14C17.0562 14 20.6362 15.79 20.6362 18V20H4.63623V18C4.63623 15.79 8.21623 14 12.6362 14Z" fill="#2EABE3" />
                            </svg>
                            <div className="flex flex-col">
                                <h5 className='text-[#1C1C1C] text-[16px] max-w-[150px]'>{data.user_name}</h5>
                                <p className='text-[#7A7A7A] text-[12px]'>{data.email}</p>
                                <p className='text-[#7A7A7A] text-[12px]'>{data.phone_number}</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-list">
                        <div className="flex flex-col text-center items-center justify-center">
                            <p>Check in</p>
                            <span className='text-[#1C1C1C] text-[14px] bg-[#2eaae326] rounded-[10px] p-2 max-w-[110px]'>
                                {data.check_in && moment(data.check_in).format(DATE_FORMAT)}
                            </span>
                        </div>
                    </div>
                    <div className="p-list">
                        <div className="flex flex-col text-center items-center justify-center">
                            <p>Check out</p>
                            <span className='text-[#1C1C1C] text-[14px] bg-[#2eaae326] rounded-[10px] p-2 max-w-[110px]'>

                                {data.check_out && moment(data.check_out).format(DATE_FORMAT)}
                            </span>
                        </div>
                    </div>
                    <div className="p-list text-center">
                        <p className='text-[#7A7A7A] text-[12px]'>Reservation Created on:</p>
                        <h4 className='text-[#1C1C1C] text-[16px]'>{formattedDate}</h4>
                    </div>
                    <div className="p-list">
                        <h5 className='text-[#1C1C1C] text-[16px] font-[800]'>{data.total_days} days</h5>
                    </div>
                    <div className="p-list flex justify-center items-center flex-col gap-1 text-center">
                        <span className='bg-[#2eaae325] min-w-[34px] w-fit py-1 px-2 h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${data.sub_total}</span>
                        <p className='text-[#7A7A7A] text-[12px]' >Subtotal</p>
                    </div>
                    {/* {taxes &&
                        <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>{data.taxes}</span>
                            <p className='text-[#7A7A7A] text-[12px]'>Taxes</p>
                        </div>
                    } */}
                    {data.discount &&
                        <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                            <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${data.discount}</span>
                            <p className='text-[#7A7A7A] text-[12px]'>Discount</p>
                        </div>
                    }
                    <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                        <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${Number(data.total).toFixed(2)}</span>
                        <p className='text-[#7A7A7A] text-[12px]'>Total</p>
                    </div>
                    {/* <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                    <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${}</span>
                    <p className='text-[#7A7A7A] text-[12px]'>Tax</p>
                </div> */}
                    <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
                        <span className='bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]'>${data.due_now}</span>
                        <p className='text-[#7A7A7A] text-[12px]'>Pay at Lot</p>
                    </div>
                    {/* <div className="p-list">
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" />
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#2eaae31f] dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2EABE3]"></div>
                    </label>
                </div> */}

                    {!noOption &&
                        <>
                            {data.status === 'cancel' ?
                                <></>
                                :
                                <div className="reservation-list-option-btn"
                                    onClick={() => {
                                        setShowOptions(!showOptions)
                                    }}
                                >
                                    <button type='button' className='bg-[#F2F2F2] w-[24px] h-[24px] rounded-[5px] flex items-center justify-center'>
                                        <svg width="3" height="14" viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.8339 1.91649C2.83396 2.10257 2.79737 2.28684 2.72621 2.45877C2.65506 2.63071 2.55074 2.78694 2.4192 2.91856C2.28767 3.05018 2.1315 3.1546 1.95961 3.22586C1.78772 3.29713 1.60348 3.33384 1.4174 3.3339C1.23133 3.33396 1.04706 3.29737 0.875126 3.22621C0.703191 3.15506 0.546954 3.05074 0.415336 2.9192C0.283718 2.78767 0.179297 2.6315 0.108033 2.45961C0.0367699 2.28772 5.98432e-05 2.10348 7.32043e-08 1.9174C-0.000120638 1.54161 0.149049 1.18115 0.414693 0.915337C0.680337 0.649522 1.04069 0.500121 1.41649 0.5C1.79229 0.499879 2.15275 0.649049 2.41856 0.914693C2.68437 1.18034 2.83378 1.5407 2.8339 1.91649Z" fill="#7A7A7A" />
                                            <path d="M1.41649 8.19627C2.1988 8.19627 2.83299 7.56208 2.83299 6.77978C2.83299 5.99747 2.1988 5.36328 1.41649 5.36328C0.634185 5.36328 0 5.99747 0 6.77978C0 7.56208 0.634185 8.19627 1.41649 8.19627Z" fill="#7A7A7A" />
                                            <path d="M1.41649 13.0595C2.1988 13.0595 2.83299 12.4254 2.83299 11.6431C2.83299 10.8607 2.1988 10.2266 1.41649 10.2266C0.634185 10.2266 0 10.8607 0 11.6431C0 12.4254 0.634185 13.0595 1.41649 13.0595Z" fill="#7A7A7A" />
                                        </svg>

                                    </button>
                                </div>
                            }
                        </>
                    }


                    {showOptions &&
                        <div className="reservation-actions">
                            {/* <button className='text-[#1C1C1C] rounded-[5px] bg-[white] py-2 w-[190px] text-center text-[16px] font-[500]' type='button'>View Reservation</button> */}
                            <button
                                onClick={() => {
                                    setShowDeleteModal(true)
                                }}
                                className={`text-[white] items-center pl-2 flex gap-2 rounded-[5px] bg-[#2EABE3] py-2 w-[190px] text-center text-[16px] font-[500]`} type='button'><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5987 11.334L7.9987 8.93398L10.3987 11.334L11.332 10.4007L8.93203 8.00065L11.332 5.60065L10.3987 4.66732L7.9987 7.06732L5.5987 4.66732L4.66536 5.60065L7.06536 8.00065L4.66536 10.4007L5.5987 11.334ZM7.9987 14.6673C7.07648 14.6673 6.20981 14.4922 5.3987 14.142C4.58759 13.7918 3.88203 13.3169 3.28203 12.7173C2.68203 12.1173 2.20714 11.4118 1.85736 10.6006C1.50759 9.78954 1.33248 8.92287 1.33203 8.00065C1.33203 7.07843 1.50714 6.21176 1.85736 5.40065C2.20759 4.58954 2.68248 3.88398 3.28203 3.28398C3.88203 2.68398 4.58759 2.2091 5.3987 1.85932C6.20981 1.50954 7.07648 1.33443 7.9987 1.33398C8.92092 1.33398 9.78759 1.5091 10.5987 1.85932C11.4098 2.20954 12.1154 2.68443 12.7154 3.28398C13.3154 3.88398 13.7905 4.58954 14.1407 5.40065C14.4909 6.21176 14.6658 7.07843 14.6654 8.00065C14.6654 8.92287 14.4903 9.78954 14.14 10.6006C13.7898 11.4118 13.3149 12.1173 12.7154 12.7173C12.1154 13.3173 11.4098 13.7924 10.5987 14.1426C9.78759 14.4929 8.92092 14.6678 7.9987 14.6673Z" fill="white" />
                                </svg>
                                Cancel Reservation</button>
                        </div>
                    }


                </div>
                {showOptions &&
                    <div
                        onClick={() => {
                            setShowOptions(false)
                        }}
                        className=" absolute left-0 z-[1] top-0 w-full  h-[100vh]"></div>
                }
            </div>

        </>
    )
}

export default RevervationListCard
