import React from 'react'
import { useState } from 'react';
import PageTitle from '../admin-components/PageTitle'
import { useEffect } from 'react';
import { GlobalContext } from "../globalContext";
import ReservationInfoBox from '../admin-components/ReservationInfoBox'
import Revenue from '../admin-components/Revenue'
import AddCoupon from '../admin-modals/AddCoupon';
import { useCallback } from "react";
import AllReservations from '../admin-components/AllReservations';
import { getDashboardData } from '../service/AdminApi/AdminApi'
import AllReservationReports from '../admin-components/AllReservationReports';
import { ReservationInfo } from '../models/api.types';
import { getReservationInfo } from '../service/AdminApi/resevationApi';
import Spinner from '../components/Spinner';


const Reservation = () => {



    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [showReservations, setShowReservations] = useState(true)

    const [showReservationReports, setShowReservationReports] = useState(false)
    const [reservationInfo, setReservationInfo] = useState<ReservationInfo | undefined>(undefined);


    useEffect(() => {
        getReservationInfo().then((data: ReservationInfo) => {
            setReservationInfo(data)
        })
    }, [])

    React.useEffect(() => {











        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "reservations",
            },
        });

        globalDispatch({
            type: "MENUBAR",
            payload: {
                menuBar: false,
            },

        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Reservations",
            },
        });

    }, []);

    return (
        <>
            <PageTitle />
            <div className="flex items-center mt-6">
                <button className={`text-[#7A7A7A] md:text-[14px] text-[12px] font-[800] uppercase border-b px-5 pb-3  ${showReservations ? 'text-[#2EABE3] border-[#2EABE3]' : 'border-[transparent]'} `} type='button'
                    onClick={() => {
                        setShowReservations(true)
                        setShowReservationReports(false)
                    }}
                >Reservations</button>
                <button className={`text-[#7A7A7A] md:text-[14px] text-[12px] font-[800] uppercase border-b px-5 pb-3  ${showReservationReports ? 'text-[#2EABE3] border-[#2EABE3]' : 'border-[transparent]'} `} type='button'
                    onClick={() => {
                        setShowReservations(false)
                        setShowReservationReports(true)
                    }}
                >Reservation reports</button>
            </div>
            {showReservations &&
                <>
                    <div className="w-full ">
                        <ReservationInfoBox today={reservationInfo?.today_reservations ?? <Spinner/>} weekly={reservationInfo?.weekly_reservations ?? <Spinner/>} monthy={reservationInfo?.monthly_reservations ?? <Spinner/>} canceled={reservationInfo?.total_cacnel ?? <Spinner/>} isCanceled={true} />
                    </div>
                    <AllReservations />
                </>
            }
            {showReservationReports &&
                <AllReservationReports />
            }
        </>
    )
}

export default Reservation
