import React, { useState } from 'react';
import ReservRevenuBox from './ReservRevenuBox'
// import { getAdminAirports } from '../service/AdminApi/AdminApi';
import { getReservationInfo } from '../service/AdminApi/resevationApi';
import { partnerOverView } from '../service/AdminApi/resevationApi';


const PartnersOverView = ({ today, weekly, monthy, sale, canceled, isCanceled }) => {
    const [airportBox, setAirportBox] = useState([])
    const [resInfo, setResInfo] = useState({})

    // const getAirport = async () => {

    //     const airportData = await getAdminAirports();
    //     setTimeout(() => {
    //         setAirportBox(airportData.data);

    //     }, 200);

    // };


    React.useEffect(() => {
        // getAirport()
        getOverview()
    }, []);

    const getOverview = async () => {
        const response = await partnerOverView()
        setResInfo(response)
    }

    return (
        <div className="w-full p-[20px] rounded-[20px] bg-white">
            <div className="flex justify-between items-center flex-wrap">
                <h4 className='md:text-[20px] text-[16px] text-[#1C1C1C] font-[400]'>Partners Overview</h4>
                {/* 
                <div className="flex items-center gap-4">
                    <div className="relative">

                   
                    <div className="flex bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800] rounded-[10px] items-center gap-2 py-2 px-3">
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.875 12.375H3.125V5.5H11.875M10 1.125V2.375H5V1.125H3.75V2.375H3.125C2.43125 2.375 1.875 2.93125 1.875 3.625V12.375C1.875 12.7065 2.0067 13.0245 2.24112 13.2589C2.47554 13.4933 2.79348 13.625 3.125 13.625H11.875C12.2065 13.625 12.5245 13.4933 12.7589 13.2589C12.9933 13.0245 13.125 12.7065 13.125 12.375V3.625C13.125 3.29348 12.9933 2.97554 12.7589 2.74112C12.5245 2.5067 12.2065 2.375 11.875 2.375H11.25V1.125M10.625 8H7.5V11.125H10.625V8Z" fill="#2EABE3" />
                        </svg>
                        Last 30 days
                    </div>
                    <input
                    onChange={(e) => {
                      setFilterDate(e.target.value);
                    }}
                    type="date"
                     className=" absolute left-[0px] top-0 w-full opacity-0 cursor-pointer h-full z-10"
                  />
                    </div>
                    
                    <div className="flex bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800] rounded-[10px] items-center gap-2 py-2 px-3">
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.74971 8V12.925C8.77471 13.1125 8.71221 13.3125 8.56846 13.4438C8.51064 13.5017 8.44196 13.5477 8.36635 13.579C8.29075 13.6104 8.2097 13.6265 8.12784 13.6265C8.04598 13.6265 7.96493 13.6104 7.88932 13.579C7.81372 13.5477 7.74504 13.5017 7.68721 13.4438L6.43097 12.1875C6.3628 12.1209 6.31097 12.0394 6.27953 11.9494C6.24808 11.8594 6.23788 11.7633 6.24972 11.6688V8H6.23097L2.63097 3.3875C2.52947 3.25721 2.48367 3.09204 2.50358 2.92808C2.52349 2.76413 2.60749 2.61472 2.73722 2.5125C2.85597 2.425 2.98722 2.375 3.12472 2.375H11.8747C12.0122 2.375 12.1435 2.425 12.2622 2.5125C12.3919 2.61472 12.4759 2.76413 12.4958 2.92808C12.5158 3.09204 12.47 3.25721 12.3685 3.3875L8.76846 8H8.74971Z" fill="#2EABE3" />
                        </svg>

                        <select className='bg-transparent border-none p-0'>
                            <option value=''>All</option>
                        {airportBox.map((item) => (
                                    <option value={item?.id}>{item?.name}</option>
                                ))}
                        </select>
                    </div>
                </div> */}
            </div>

            <div className="prt-cells grid gap-3 xl:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-4">
                <ReservRevenuBox Count={resInfo?.total_partners ?? 0} BoxTag='Active Partners' />
                <ReservRevenuBox Symbol={true} Count={resInfo?.total_revenue ?? 0} BoxTag='Revenue' />
                <ReservRevenuBox Symbol={true} Count={resInfo?.total_sales ?? 0} BoxTag='Sales' />
                <ReservRevenuBox Count={resInfo?.total_reservations ?? 0} BoxTag='Reservations' />
                <ReservRevenuBox Count={resInfo?.total_cacnelations ?? 0} BoxTag='Cancellations' isCanceled={isCanceled} />
            </div>
        </div>
    )
}

export default PartnersOverView
