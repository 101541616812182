import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import LotImg from "../assets/images/placeholder-img.jpg";
import PartnerLotGallery from "../view-partner-components/PartnerLotGallery";
import AboutPartner from "../view-partner-components/AboutPartner";
import PartnerPackagesSec from "../view-partner-components/PartnerPackagesSec";
import PartnerTaxesSec from "../view-partner-components/PartnerTaxesSec";
import PartnerBusinessSec from "../view-partner-components/PartnerBusinessSec";
import PartnersMarketing from "../PartnersMarketing";
import PartnersReviewSec from "../view-partner-components/PartnersReviewSec";

import Loader from "../admin-components/Loader";
import { getAdminSinglePartner } from "../service/AdminApi/partnerApi";
import PartnerProfileImage from "../view-partner-components/PartnerProfileImage";

const ViewPartner = () => {
  const [lotDetails, setLotDetails] = useState(true);
  const [pricing, setPricingSec] = useState(false);
  const [infos, setInfos] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [review, setReview] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [partnerData, setPartnerData] = useState([]);
  const [partnerId, setPartnerId] = useState("");
  const [airportId, setAirportId] = useState("");
  // alert('jdkjdk')
  const params = useParams();
  const getData = async () => {
    const partnerResponse = await getAdminSinglePartner(params.id);

    setPartnerData(partnerResponse);
    setIsLoading(false);
  };

  const NavigateBack = () => {
    if (!params.id) {
      navigate("/admin/partners");
    }
  };

  React.useEffect(() => {
    getData();
    NavigateBack();

    setPartnerId(params.id);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="flex items-center justify-between mt-4">
            <NavLink to="/admin/partners">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="30" height="30" rx="15" fill="#2EABE3" />
                <path
                  d="M20 15H10"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15 20L10 15L15 10"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </NavLink>
            <NavLink
              to={`/admin/partner_reservation/${partnerId}`}
              className="text-[#FFFFFF] md:text-[16px] text-[14px] font-[500] bg-[#2EABE3] md:py-4 py-2 md:px-8 px-4 rounded-[10px]"
            >
              View All Reservations
            </NavLink>
          </div>
          <div className="w-full md:block hidden">
            <div className="flex xl:flex-row flex-col w-full mt-10 gap-6 mb-10 ">
              {/* <PartnerProfileImage data={partnerData} /> */}
              <PartnerLotGallery data={partnerData} />
              <div className="flex flex-col gap-5 w-full overflow-hidden">
                <AboutPartner getData={getData} partnerData={partnerData} />
                {/* <PartnerPackagesSec partnerData={partnerData} /> */}
                <PartnerTaxesSec data={partnerData} />
                <PartnerBusinessSec partnerData={partnerData} />
                <PartnersMarketing data={partnerData} />
                <PartnersReviewSec partnerData={partnerData} />
              </div>
            </div>
          </div>
          <div className="view-partner-btn-holder md:hidden flex justify-between gap-2 mt-10 mb-2">
            <button
              onClick={() => {
                setLotDetails(true);
                setPricingSec(false);
                setInfos(false);
                setMarketing(false);
                setReview(false);
              }}
              className={`${lotDetails
                ? "text-[#2EABE3] border-[#2EABE3]"
                : "text-[#7A7A7A]"
                } border-b text-[12px] font-[800] uppercase `}
              type="button"
            >
              Lot Details
            </button>
            <button
              onClick={() => {
                setLotDetails(false);
                setPricingSec(true);
                setInfos(false);
                setMarketing(false);
                setReview(false);
              }}
              className={`${pricing ? "text-[#2EABE3] border-[#2EABE3]" : "text-[#7A7A7A]"
                } border-b text-[12px] font-[800] uppercase`}
              type="button"
            >
              Pricing
            </button>
            <button
              onClick={() => {
                setLotDetails(false);
                setPricingSec(false);
                setInfos(true);
                setMarketing(false);
                setReview(false);
              }}
              className={`${infos ? "text-[#2EABE3] border-[#2EABE3]" : "text-[#7A7A7A]"
                } border-b text-[12px] font-[800] uppercase`}
              type="button"
            >
              infos
            </button>
            <button
              onClick={() => {
                setLotDetails(false);
                setPricingSec(false);
                setInfos(false);
                setMarketing(true);
                setReview(false);
              }}
              className={`${marketing ? "text-[#2EABE3] border-[#2EABE3]" : "text-[#7A7A7A]"
                } border-b text-[12px] font-[800] uppercase`}
              type="butoon"
            >
              Marketing
            </button>
            <button
              onClick={() => {
                setLotDetails(false);
                setPricingSec(false);
                setInfos(false);
                setMarketing(false);
                setReview(true);
              }}
              className={`${review ? "text-[#2EABE3] border-[#2EABE3]" : "text-[#7A7A7A]"
                } border-b text-[12px] font-[800] uppercase`}
              type="butoon"
            >
              Reviews
            </button>
          </div>
          <div className="w-full md:hidden block mt-4">
            <div className="flex flex-col gap-5 w-full">
              {lotDetails && (
                <div className=" flex flex-col gap-4">
                  <PartnerLotGallery data={partnerData} />
                  <AboutPartner getData={getData} partnerData={partnerData} />
                </div>
              )}
              {pricing && (
                <div className=" flex flex-col gap-4">
                  {/* <PartnerPackagesSec /> */}
                  <PartnerTaxesSec data={partnerData} />
                </div>
              )}

              {infos && <PartnerBusinessSec partnerData={partnerData} />}
              {marketing && <PartnersMarketing data={partnerData} />}
              {review && <PartnersReviewSec partnerData={partnerData} />}




            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewPartner;
