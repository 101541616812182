import React from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PublicHeader from "./components/PublicHeader";
import SnackBar from "./components/SnackBar";
// import SnackBar from "./components/SnackBar";
import AdminHeader from "./components/AdminHeader";
import { AuthContext } from "./AuthContext";

import AdminLoginPage from "./pages/AdminLoginPage";
import NotFoundPage from "./pages/NotFoundPage";
import Home from "./pages/Home";
import SearchAirport from "./pages/SearchAirport";
import Footer from "./home-components/Footer";
import CheckOutSignup from "./pages/CheckOutSignup";
import TopHeader from "./admin-components/TopHeader";
import Dashboard from "./admin/Dashboard";
import PaymentPage from "./pages/PaymentPage";
import CompletePage from "./pages/CompletePage";
import Reservation from "./admin/Reservation";
import Partners from "./admin/Partners";
import ViewPartner from "./admin/ViewPartner";
import EditReservationPage from "./pages/EditReservationPage";
import Coupons from "./admin/Coupons";
import PartnerDashboard from "./partner-dashboard/Dashboard";
import PartnerHeader from "./components/PartnerHeader";
import Profile from "./admin/Profile";
import Airports from "./admin/Airports";
import PartnerReservations from "./admin/PartnerReservations";
import Profile2 from "./partner-component/Profile";
import AdminForgotPage from "./pages/AdminForgotPage";
import AdminResetPage from "./pages/AdminResetPage";
import CheckInCheckOut from "./admin/CheckInCheckOut";
import NewsletterEmail from "./admin/NewsletterEmail";
import AutoLogout from "./components/AutoLogout";

function renderHeader(role) {
  switch (role) {
    case "admin":
      return <AdminHeader />;
    case "partner":
      return <PartnerHeader />;
    default:
      return <PublicHeader />;
  }
}

function renderRoutes(role) {
  switch (role) {
    case "admin":
      return (
        <Routes>
          {/* <Route exact path="/admin" element={<AdminDashboardPage />}></Route> */}

          <Route
            path="*"
            element={<Navigate to="/admin" /> || <NotFoundPage />}
          ></Route>
          <Route exact path="/admin" element={<Dashboard />}></Route>
          <Route exact path="/" element={<Dashboard />}></Route>
          <Route exact path="/dashboard" element={<Dashboard />}></Route>
          <Route
            exact
            path="/admin/reservations"
            element={<Reservation />}
          ></Route>
          <Route exact path="/admin/partners" element={<Partners />}></Route>
          <Route
            exact
            path="/admin/view_partner/:id"
            element={<ViewPartner />}
          ></Route>
          <Route exact path="/admin/coupon" element={<Coupons />}></Route>
          <Route exact path="/profile" element={<Profile />}></Route>
          <Route exact path="/admin/airports" element={<Airports />}></Route>
          <Route
            exact
            path="/admin/partner_reservation/:id"
            element={<PartnerReservations />}
          ></Route>
          <Route
            exact
            path="/checkin-checkout"
            element={<CheckInCheckOut />}
          ></Route>
          <Route
            exact
            path="/admin/emails"
            element={<NewsletterEmail />}
          ></Route>
        </Routes>
      );

    case "partner":
      return (
        <Routes>
          {/* <Route exact path="/admin" element={<AdminDashboardPage />}></Route> */}
          <Route
            path="*"
            element={<Navigate to="/" /> || <NotFoundPage />}
          ></Route>
          <Route exact path="/" element={<PartnerDashboard />}></Route>
          <Route exact path="/dashboard" element={<PartnerDashboard />}></Route>
          <Route exact path="/profile" element={<Profile2 />}></Route>
          <Route
            exact
            path="/checkin-checkout"
            element={<CheckInCheckOut />}
          ></Route>
        </Routes>
      );

    default:
      return (
        <Routes>
          <Route exact path="/login" element={<AdminLoginPage />}></Route>
          <Route exact path="/forgot" element={<AdminForgotPage />}></Route>
          <Route exact path="/" element={<AdminLoginPage />}></Route>
          <Route
            exact
            path="/reset-password"
            element={<AdminResetPage />}
          ></Route>
          {/* <Route exact path="/search" element={<SearchAirport />}></Route>
          <Route path="/payment" exact element={<PaymentPage />}></Route>
          <Route exact path="/checkout_&_signup" element={<CheckOutSignup />}></Route>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="*" exact element={<NotFoundPage />}></Route>
          <Route path="/reservation_Complete" exact element={<CompletePage />}></Route>
          <Route path="/edit_reservation" exact element={<EditReservationPage />}></Route>
          <Route path="/CompletePage" exact element={<CompletePage />}></Route> */}
        </Routes>
      );
  }
}

function Main() {
  const { state } = React.useContext(AuthContext);

  return (
    <div className="h-full w-full">
      <>
        {state.role === "admin" || state.role === "partner" ? (
          <>
            <div className="flex w-full">
              {renderHeader(state.role)}
              {state.role === "partner" && <AutoLogout />}
              <div className="page-content md:px-[30px] px-[15px] bg-[#F9F9F9]">
                <TopHeader />
                {renderRoutes(state.role)}
              </div>
            </div>
          </>
        ) : (
          <>
            {/* {renderHeader(state.role)} */}
            {renderRoutes(state.role)}
            {/* <Footer /> */}
          </>
        )}
      </>

      {/* {renderHeader()}
    {renderRoutes()}
    <Footer />  */}

      <SnackBar />
    </div>
  );
}

export default Main;
