import React from 'react'
import { Reservation } from '../models/types';
import { DATE_FORMAT, getDaysBetweenDates, ONLY_DATE_FORMAT } from '../utils/utils';
import moment from 'moment';

const reservations = [
    // Your data here, formatted as objects
    { txnId: 'PM1', customerName: 'Angel Laureano', checkInDate: '2024-07-02/03:00 AM', checkOutDate: '2024-07-07/02:00 PM', duration: '5 days' },
    // Add all other rows similarly
];
interface ReservationModalProps {
    isOpen: boolean;
    reservations: Reservation[];
    date: string
    type?: 'CHECKIN' | 'CHECKOUT' | ''
    onClose: () => void;
}
const CheckInCheckOutModal = ({ isOpen, reservations, date, type, onClose }: ReservationModalProps) => {
    if (!isOpen) return null;

    const handleClickOutside = (e) => {
        if (e.target.className === 'modal') {
            onClose();
        }
    };

    return (
        <div className="modal-holder modal" onClick={handleClickOutside}>
            <div className="modal-outer" onClick={onClose}></div>
            <div className="modal-content   bg-[white] p-4 rounded-[10px] w-full max-w-[700px]">

                <h2 className='text-[14px] mb-4 font-[700]'>Date: {moment(date).format(ONLY_DATE_FORMAT)}</h2>

                <div className="overflow-x-auto w-full">
                    <table className='checkin-table'>
                        <thead>
                            <tr>
                                <th className='text-left'>Resevation ID</th>
                                <th>Customer Name</th>


                                {(type == 'CHECKIN'  || type == '')&& <th>Check In Date</th>}

                                {(type == 'CHECKOUT' || type == '') && <th>Check Out Date</th>}

                                <th className='text-center'>Duration</th>
                                <th className='text-right'>Pay at Lot</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reservations.map((res, index) => (
                                <tr key={index}>
                                    <td>PM{res.id}</td>
                                    <td className='text-center'>{res.user_name}</td>


                                    {(type == 'CHECKIN' || type == '') && <td className='text-center'>{moment(res.check_in).format(DATE_FORMAT)}</td>}

                                    {(type == 'CHECKOUT'  || type == '') && <td className='text-center'>{moment(res.check_out).format(DATE_FORMAT)}</td>}

                                    <td className='text-center'>{getDaysBetweenDates(res.check_in, res.check_out)} days</td>
                                    {res.due_now && <td className='text-right'>${res.due_now}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};


export default CheckInCheckOutModal
