import React from "react";
import { useState } from "react";
import { GlobalContext, showToast } from "../globalContext";
import EditAirport from "./EditAirport";
import { useCallback } from "react";
import DeleteModal from "./DeleteModal";
import { deleteAirport } from "../service/AdminApi/AdminApi";
import { Airport } from "../models/types";
import AddAirport from "../admin-modals/AddAirport";

type AirportListCardProps = {
  onRefresh?: () => void
  data: Airport
  index: number
}


const AirportListCard = (props: AirportListCardProps) => {
  const { data, onRefresh, index } = props
  const { name, code, location, city, id } = data;
  const { dispatch: globalDispatch } = React.useContext(GlobalContext);

  const [showCancel, setShowCancel] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const onCloseEditModal = useCallback(() => {
    setShowEditModal(false);
  }, [showEditModal]);
  const onCloseDeleteModal = useCallback(() => {
    setDeleteModal(false);
  }, [deleteModal]);

  const onAirportDelete = () => {
    setIsLoading(true);

    deleteAirport(id, (success: any, resp: any) => {
      if (success) {
        onRefresh && onRefresh();
        showToast(globalDispatch, "Deleted");
        setIsLoading(false);
      } else {
        alert("something went wrong!");
      }
    });
  };

  return (
    <>
      {showEditModal && (
        <AddAirport
          isEdit
          onRefresh={() => {
            onRefresh && onRefresh();
          }}
          data={data}
          onClose={() => {
            onCloseEditModal()
          }}
        />
      )}

      {deleteModal && (
        <DeleteModal closeModal={onCloseDeleteModal} deleteCard={onAirportDelete} />
      )}

      <div className="relative fader">
        <div className="flex items-center p-list-holder airport-p-list-holder justify-between gap-10">
          <div className="p-list flex items-center gap-3 ">
            <p>{index}</p>
            <div className="flex flex-col">
              <h4 className="text-[#202020] text-[16px]">
                <b>{name}</b> {code} , {city}
              </h4>
            </div>
          </div>

          <div className="p-list flex justify-start  items-start  flex-col gap-1 text-left min-w-[300px]">
            <span className="bg-[#2eaae325] min-w-[34px] max-w-[250px] py-1 px-3 w-fit min-h-[34px] flex items-center justify-center gap-1 rounded-[20px] bg-[#FEE9F2]  text-[#000000] text-[14px]">
              <svg
                className="min-w-[17px] w-[17px]"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.66667 8.16732C8.22464 8.16732 7.80072 7.99172 7.48816 7.67916C7.17559 7.3666 7 6.94268 7 6.50065C7 6.05862 7.17559 5.6347 7.48816 5.32214C7.80072 5.00958 8.22464 4.83398 8.66667 4.83398C9.10869 4.83398 9.53262 5.00958 9.84518 5.32214C10.1577 5.6347 10.3333 6.05862 10.3333 6.50065C10.3333 6.71952 10.2902 6.93625 10.2065 7.13846C10.1227 7.34067 9.99994 7.5244 9.84518 7.67916C9.69041 7.83393 9.50668 7.95669 9.30447 8.04045C9.10226 8.12421 8.88554 8.16732 8.66667 8.16732ZM8.66667 1.83398C7.42899 1.83398 6.242 2.32565 5.36683 3.20082C4.49167 4.07599 4 5.26297 4 6.50065C4 10.0007 8.66667 15.1673 8.66667 15.1673C8.66667 15.1673 13.3333 10.0007 13.3333 6.50065C13.3333 5.26297 12.8417 4.07599 11.9665 3.20082C11.0913 2.32565 9.90434 1.83398 8.66667 1.83398Z"
                  fill="#ED1A75"
                />
              </svg>

              {location}
            </span>
          </div>

          <div className="p-list flex justify-center items-center  flex-col gap-1 text-center">
            <span className="bg-[#2eaae325] min-w-[34px] py-1 px-2 w-fit h-[34px] flex items-center justify-center rounded-[20px] text-[#2EABE3] text-[16px]">
              {data.partners_count}
            </span>
            <p className="text-[#7A7A7A] text-[12px]">Partner{Number(data?.partners_count) > 1 && 's'}</p>

          </div>

          <button
            type="button"
            className="bg-[#F2F2F2] w-[24px] h-[24px] min-w-[24px] rounded-[5px] flex items-center justify-center"
            onClick={() => {
              setShowCancel(!showCancel);
            }}
          >
            <svg
              width="3"
              height="14"
              viewBox="0 0 3 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.8339 1.91649C2.83396 2.10257 2.79737 2.28684 2.72621 2.45877C2.65506 2.63071 2.55074 2.78694 2.4192 2.91856C2.28767 3.05018 2.1315 3.1546 1.95961 3.22586C1.78772 3.29713 1.60348 3.33384 1.4174 3.3339C1.23133 3.33396 1.04706 3.29737 0.875126 3.22621C0.703191 3.15506 0.546954 3.05074 0.415336 2.9192C0.283718 2.78767 0.179297 2.6315 0.108033 2.45961C0.0367699 2.28772 5.98432e-05 2.10348 7.32043e-08 1.9174C-0.000120638 1.54161 0.149049 1.18115 0.414693 0.915337C0.680337 0.649522 1.04069 0.500121 1.41649 0.5C1.79229 0.499879 2.15275 0.649049 2.41856 0.914693C2.68437 1.18034 2.83378 1.5407 2.8339 1.91649Z"
                fill="#7A7A7A"
              />
              <path
                d="M1.41649 8.19627C2.1988 8.19627 2.83299 7.56208 2.83299 6.77978C2.83299 5.99747 2.1988 5.36328 1.41649 5.36328C0.634185 5.36328 0 5.99747 0 6.77978C0 7.56208 0.634185 8.19627 1.41649 8.19627Z"
                fill="#7A7A7A"
              />
              <path
                d="M1.41649 13.0595C2.1988 13.0595 2.83299 12.4254 2.83299 11.6431C2.83299 10.8607 2.1988 10.2266 1.41649 10.2266C0.634185 10.2266 0 10.8607 0 11.6431C0 12.4254 0.634185 13.0595 1.41649 13.0595Z"
                fill="#7A7A7A"
              />
            </svg>
          </button>
        </div>
        {showCancel && (
          <>
            <div className="reservation-actions fader">
              <button
                onClick={() => {
                  setDeleteModal(true);
                }}
                className={`text-[white] items-center pl-2 flex gap-2 rounded-[5px] bg-[red] py-2 w-[190px] text-center text-[16px] font-[500]`}
                type="button"
              >
                Delete Airport
              </button>
              <button
                onClick={() => {
                  setShowEditModal(true);
                }}
                className={`text-[white] items-center pl-2 flex gap-2 rounded-[5px] bg-[#2EABE3] py-2 w-[190px] text-center text-[16px] font-[500]`}
                type="button"
              >
                Edit Airport
              </button>
            </div>
          </>
        )}
      </div>
      {showCancel && (
        <div
          className="close-overlay "
          onClick={() => {
            setShowCancel(false);
          }}
        ></div>
      )}
    </>
  );
};

export default AirportListCard;
