import React from "react";
import { useState } from "react";
import PageTitle from "../admin-components/PageTitle";
import { useEffect } from "react";
import { GlobalContext } from "../globalContext";
import {
    exportEmailCSV,
    getAllNewsLetterSubscribers,
} from "../service/AdminApi/AdminApi";
import Spinner from "../components/Spinner";
import { downloadCSV } from "../utils/utils";

const NewsletterEmail = () => {
    const { dispatch: globalDispatch } = React.useContext(GlobalContext);

    const [showEmails, setShowEmails] = useState();
    const [subscribers, setSubscribers] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const [exporting, setExporting] = useState(false);
    useEffect(() => {
        setIsloading(true);
        getAllNewsLetterSubscribers().then((data) => {
            setIsloading(false);
            setSubscribers(data);
        });
    }, []);
    React.useEffect(() => {
        globalDispatch({
            type: "SETPATH",
            payload: {
                path: "emails",
            },
        });

        globalDispatch({
            type: "MENUBAR",
            payload: {
                menuBar: false,
            },
        });

        globalDispatch({
            type: "SETPAGENAME",
            payload: {
                pageName: "Subscribers Emails",
            },
        });
    }, []);

    const exportDownloadEmailCSV = async () => {
        setExporting(true)
        const url = await exportEmailCSV();
        downloadCSV(url, "Subscribers");
        setExporting(false)
    };
    return (
        <>
            <PageTitle />
            <div className="w-full p-[20px]  rounded-[20px] bg-white mt-4">
                <div className="relative fader">
                    <button
                    className="text-[white] flex items-center gap-2 bg-[#1C1C1C] p-3 rounded-[10px] ml-auto mb-4"
                        onClick={() => {
                            exportDownloadEmailCSV();
                        }}
                    >
                        download report {exporting && <Spinner />}
                    </button>

                    {isLoading && <Spinner />}
                    {subscribers.slice().reverse().map((item,index) => {
                        return (
                            <div className="flex items-center p-list-holder gap-10 py-2 border-b border-[black  ]">
                                <div className="p-list flex items-center gap-10 ">
                                    <p>{index + 1}</p>
                                    <div className="flex flex-col">
                                        <h4 className="text-[#202020] text-[16px]">
                                            <b>{item.email}</b>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>
        </>
    );
};

export default NewsletterEmail;
