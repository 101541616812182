import React, { useEffect, useState } from "react";
import PartnersList from "./PartnersList";
import Pagination from "./Pagination";
import { useCallback } from "react";
import OrderListingModal from "../admin-modals/OrderListingModal";
import AddPartnersModal from "../admin-modals/AddPartnersModal";

import Loader from "./Loader";

import { getAdminAirports } from "../service/AdminApi/AdminApi";
import { getAdminPartners } from "../service/AdminApi/partnerApi";

const AllPartners = ({ dashboardPartners }) => {
  const [filterDate, setFilterDate] = useState("");
  const [listingModal, setListingModal] = useState(false);
  const [addPartnerModal, setAddPartnerModal] = useState(false);
  const [partnerBox, setPartnerBox] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [airportBox, setAirportBox] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [airportId, setAirportId] = useState("");

  const [searchquery, setSearchQuery] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const onCloseListingModal = useCallback(() => {
    setListingModal(false);
  }, [listingModal]);

  const onCloseAddPartnerModal = useCallback(() => {
    setAddPartnerModal(false);
  }, [addPartnerModal]);

  const getData = async (airportID, searchquery, currentPage) => {
    try {
      setIsLoading(true);
      const response = await getAdminPartners(airportID, searchquery, currentPage);
      const tPages = Math.ceil(Number(response?.total) / 10);
      setTotalPages(tPages == 0 ? 1 : tPages);
      setCurrentPage(response.current_page);
      setPartnerBox(response.data);
      setIsLoading(false);
    } catch (error) {

      setPartnerBox([]);
      setIsLoading(false);
      setTotalPages(0);
      setCurrentPage(0);
    }
  };

  const getAirport = async () => {
    const partnerData = await getAdminAirports();
    setTimeout(() => {
      setAirportBox(partnerData.data);
    }, 200);
  };

  React.useEffect(() => {
    getAirport();
  }, []);

  useEffect(() => {
    // Set a timeout to update the debouncedSearchText after 500ms
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchquery);
    }, 500);

    // Clear the timeout if searchText changes (i.e., the user is still typing)
    return () => {
      clearTimeout(handler);
    };
  }, [searchquery]);


  React.useEffect(() => {
    getData(airportId, searchquery, currentPage);
  }, [airportId, debouncedSearchText, currentPage]);

  return (
    <>
      {listingModal && (
        <OrderListingModal onClose={onCloseListingModal} />
      )}
      {addPartnerModal && (
        <AddPartnersModal getData={() => {
          getData(airportId, searchquery, currentPage);
        }} onCLose={onCloseAddPartnerModal} />
      )}

      <div className="w-full p-[20px]  rounded-[20px] bg-white mt-4">
        <div className="flex xl:flex-row gap-4 flex-col justify-between xl:items-center ">
          <h4 className="md:text-[20px] text-[16px] text-[#1C1C1C] font-[400] uppercase">
            All Partners
          </h4>
          <div className="flex gap-3 items-center flex-wrap">
            {!dashboardPartners && (
              <>
                <button
                  onClick={() => {
                    setListingModal(true);
                  }}
                  type="button"
                  className="flex text-[#1C1C1C] font-[800] items-center gap-2 bg-[#F2F2F2] p-3 rounded-[10px]"
                >
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2151_13988)">
                      <path
                        d="M0.70315 2.14062C0.70315 1.75098 1.01663 1.4375 1.40628 1.4375H2.34378C2.73342 1.4375 3.0469 1.75098 3.0469 2.14062V5.65625H3.51565C3.9053 5.65625 4.21878 5.96973 4.21878 6.35938C4.21878 6.74902 3.9053 7.0625 3.51565 7.0625H1.1719C0.782252 7.0625 0.468775 6.74902 0.468775 6.35938C0.468775 5.96973 0.782252 5.65625 1.1719 5.65625H1.64065V2.84375H1.40628C1.01663 2.84375 0.70315 2.53027 0.70315 2.14062ZM2.54006 10.4961C2.34963 10.2793 2.00393 10.2939 1.83694 10.5312L1.50881 10.9854C1.28323 11.3018 0.843775 11.375 0.527369 11.1494C0.210963 10.9238 0.137721 10.4844 0.363307 10.168L0.688502 9.71094C1.38284 8.73828 2.80667 8.66797 3.59475 9.56738C4.21878 10.2822 4.20413 11.3516 3.56253 12.0488L2.54299 13.1562H3.51565C3.9053 13.1562 4.21878 13.4697 4.21878 13.8594C4.21878 14.249 3.9053 14.5625 3.51565 14.5625H0.937525C0.659205 14.5625 0.404322 14.3984 0.292994 14.1406C0.181666 13.8828 0.231471 13.5869 0.418971 13.3818L2.52835 11.0967C2.68362 10.9268 2.68655 10.6689 2.53713 10.4961H2.54006ZM6.56253 2.375H14.0625C14.5811 2.375 15 2.79395 15 3.3125C15 3.83105 14.5811 4.25 14.0625 4.25H6.56253C6.04397 4.25 5.62503 3.83105 5.62503 3.3125C5.62503 2.79395 6.04397 2.375 6.56253 2.375ZM6.56253 7.0625H14.0625C14.5811 7.0625 15 7.48145 15 8C15 8.51855 14.5811 8.9375 14.0625 8.9375H6.56253C6.04397 8.9375 5.62503 8.51855 5.62503 8C5.62503 7.48145 6.04397 7.0625 6.56253 7.0625ZM6.56253 11.75H14.0625C14.5811 11.75 15 12.1689 15 12.6875C15 13.2061 14.5811 13.625 14.0625 13.625H6.56253C6.04397 13.625 5.62503 13.2061 5.62503 12.6875C5.62503 12.1689 6.04397 11.75 6.56253 11.75Z"
                        fill="#1C1C1C"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2151_13988">
                        <rect
                          width="15"
                          height="15"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  Manage Order Listings
                </button>
              </>
            )}
            <div className="flex items-center gap-2 bg-[#F2F2F2] p-3 rounded-[10px]  sm:w-fit w-full">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2076_15039)">
                  <path
                    d="M15.3757 11.6195C15.5431 11.6736 15.6801 11.5741 15.6801 11.3981V10.28C15.6801 10.104 15.5562 9.88639 15.4052 9.79615L9.55495 6.30975C9.40391 6.21983 9.28007 6.00191 9.28007 5.82623V1.92031C9.28007 1.74431 9.22631 1.46687 9.16039 1.30335C9.16039 1.30335 8.81127 0.436793 8.20039 0.340153C8.13507 0.327785 8.06879 0.321146 8.00231 0.320312H7.99911C7.92249 0.320576 7.84607 0.328292 7.77095 0.343353L7.70183 0.358713L7.59687 0.391352C7.08871 0.560952 6.82695 1.29855 6.82695 1.29855C6.76208 1.4997 6.72609 1.70905 6.72007 1.92031V5.82623C6.72007 6.00223 6.59623 6.21983 6.44519 6.31007L0.594948 9.79615C0.443908 9.88639 0.320068 10.104 0.320068 10.28V11.3981C0.320068 11.5741 0.457028 11.6736 0.624388 11.6192L6.41575 9.73919C6.58311 9.68511 6.72007 9.78463 6.72007 9.96063V13.0112C6.72007 13.1872 6.60487 13.4179 6.46407 13.5235L5.69607 14.1002C5.55527 14.2058 5.44007 14.4365 5.44007 14.6125V15.3603C5.44007 15.5363 5.57863 15.6406 5.74759 15.592L7.69159 15.0336C7.86055 14.985 8.13767 14.985 8.30663 15.0336L10.2525 15.592C10.4215 15.6406 10.5601 15.5363 10.5601 15.3603V14.6122C10.5601 14.4362 10.4449 14.2054 10.3041 14.0998L9.53607 13.5232C9.39559 13.4176 9.28007 13.1869 9.28007 13.0109V9.96031C9.28007 9.78431 9.41703 9.68479 9.58439 9.73919L15.3757 11.6195Z"
                    fill="#2EABE3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2076_15039">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Airports:
              <select
                onChange={(e) => {

                  setAirportId(e.target.value)
                }}
                className="p-0 border-none  bg-transparent sm:max-w-[140px] max-w-[100%] sm:w-fit w-[100%] overflow-hidden"
              >
                <option value="">Select</option>
                {airportBox.map((item) => (
                  <option value={item?.id}>{item?.name}</option>
                ))}
              </select>
            </div>
            {!dashboardPartners && (
              <button
                onClick={() => {
                  setAddPartnerModal(true);
                }}
                type="button"
                className="text-[white] flex items-center gap-2 bg-[#1C1C1C] p-3 rounded-[10px] "
              >
                Add Partner{" "}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.33301 8.66732H3.33301V7.33398H7.33301V3.33398H8.66634V7.33398H12.6663V8.66732H8.66634V12.6673H7.33301V8.66732Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}

            <input
              className="bg-[#F2F2F2] p-3 rounded-[10px] border-none sm:w-fit w-full"
              type="search"
              placeholder="Search"
              value={searchquery}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
            />
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="pt-6 pb-20 overflow-auto border-t border-b border-[#E3E3E3] mt-6 mb-6 flex flex-col gap-6">

              {partnerBox.length > 0 ?
                partnerBox.map((item, index) => (
                  <>
                    <PartnersList
                      index={index + 1}
                      data={item}
                      onRefresh={() => {
                        getData(airportId, searchquery, currentPage);
                      }}
                    />
                  </>
                ))
                : <p className="text-center text-[#2EABE3] md:text-[20px] text-[16px]">No Partners Found</p>}

            </div>
            <div className="mx-auto text-center w-fit">
              <Pagination totalPages={totalPages} currentPage={currentPage}
                onPage={(page) => {
                  setCurrentPage(page)
                }}
                onNext={() => {
                  setCurrentPage(currentPage + 1)
                }}
                onPrev={() => {
                  setCurrentPage(currentPage - 1)
                }} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AllPartners;
