import React, { useEffect } from 'react'
import { useState } from 'react';
import Loader from '../admin-components/Loader';
import CheckInCheckOutModal from './CheckInCheckOutModal';
import moment from "moment";
import { getPartnerAllReservations, getPartnerReservations } from '../service/AdminApi/resevationApi';
import { Reservation } from '../models/types';
import dayjs from 'dayjs';
import { getAllDatesOfMonth, getDatesBetween, ONLY_DATE_FORMAT } from '../utils/utils';
import { AuthContext } from "../AuthContext";
type DailyReport = {
    date: string;
    checkInCount: number;
    checkOutCount: number;
};
const CheckInCheckOut = () => {
    const { user } = React.useContext(AuthContext).state;
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reservations, setReservations] = useState<Reservation[]>([]);
    const [selectedMonth, setSelectedMonth] = useState<string>(dayjs().format('YYYY-MM'));
    const [selectedDateReservations, setSelectedDateReservations] = useState<Reservation[]>([]);
    const [selectedRowDate, setSelectedRowDate] = useState('');
    const [modalDataType, setModalDataType] = useState<'' | 'CHECKIN' | 'CHECKOUT'>('');

    const [dailyReport, setDailyReport] = useState<DailyReport[]>([]);

    useEffect(() => {
        // getReservations("")
        getReservations(user?.role != 'admin' ? user?.partner_id : '')
    }, [])
    useEffect(() => {
        const filteredReservations = filterReservationsByMonth(reservations, selectedMonth);
        const report = generateDailyReport(filteredReservations);
        setDailyReport(report);
    }, [reservations, selectedMonth]);

    const filterReservationsByMonth = (reservations: Reservation[], month: string): Reservation[] => {
        return reservations.filter(reservation =>
        //    ( dayjs(reservation.check_in).format('YYYY-MM') === month)
        (dayjs(reservation.check_in).format('YYYY-MM') === month
            || dayjs(reservation.check_out).format('YYYY-MM') === month)
        );
    };

    const generateDailyReport = (reservations: Reservation[]): DailyReport[] => {
        const report: { [date: string]: DailyReport } = {};
        getAllDatesOfMonth(selectedMonth)?.forEach((date) => {
            report[date] = {
                checkInCount: 0,
                checkOutCount: 0,
                date: date
            }
        })
        reservations.forEach(reservation => {


            const checkInDate = dayjs(reservation.check_in).format('YYYY-MM-DD');
            const checkOutDate = dayjs(reservation.check_out).format('YYYY-MM-DD');
            const isCheckoutIsIncurrentMonth = dayjs(reservation.check_out).format('YYYY-MM') == selectedMonth;
            const isCheckinIsIncurrentMonth = dayjs(reservation.check_in).format('YYYY-MM') == selectedMonth;

            if (!report[checkInDate] && isCheckinIsIncurrentMonth) {
                report[checkInDate] = { date: checkInDate, checkInCount: 0, checkOutCount: 0 };
            }
            if (!report[checkOutDate] && isCheckoutIsIncurrentMonth) {
                report[checkOutDate] = { date: checkOutDate, checkInCount: 0, checkOutCount: 0 };
            }


            if (isCheckinIsIncurrentMonth) report[checkInDate].checkInCount += 1;
            if (isCheckoutIsIncurrentMonth) report[checkOutDate].checkOutCount += 1;
        });
        console.log({ report });

        return Object.values(report).sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
    };
    const getReservations = async (partID) => {
        try {
            setIsLoading(true);
            const response = await getPartnerAllReservations(partID);
            setReservations(response.data);



            setIsLoading(false);
        } catch (error) {

            setReservations([]);

            setIsLoading(false);
        }
    };
    const handleRowClick = (date: string, type?: "CHECKOUT" | "CHECKIN") => {
        setSelectedRowDate(date)
        let reservationsForDate = []

        setModalDataType(type ?? '')
        if (type == 'CHECKIN') {
            reservationsForDate = reservations.filter(reservation =>
                dayjs(reservation.check_in).format('YYYY-MM-DD') === date
            );
        } else if (type == 'CHECKOUT') {
            reservationsForDate = reservations.filter(reservation =>
                dayjs(reservation.check_out).format('YYYY-MM-DD') === date
            );
        } else {
            reservationsForDate = reservations.filter(reservation =>
                dayjs(reservation.check_in).format('YYYY-MM-DD') === date || dayjs(reservation.check_out).format('YYYY-MM-DD') === date
            );
        }

        setSelectedDateReservations(reservationsForDate);
        openModal()
    };
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    return (
        <>

            <CheckInCheckOutModal type={modalDataType} date={selectedRowDate} reservations={selectedDateReservations} isOpen={isModalOpen} onClose={closeModal} />
            <div className="w-full py-[20px] px-[10px] rounded-[20px] bg-white mt-6">
                <div className="flex justify-between items-center flex-wrap gap-3 ">
                    <div className="flex w-full justify-between items-center md:gap-0 gap-2 flex-wrap row-gap-3 ">
                        <h4 className="md:text-[20px] text-[16px] text-[#1C1C1C] font-[400] uppercase">
                            All Checkins and Checkouts
                        </h4>
                        <div className="flex items-center gap-8 filter-check-btns ">
                            <div className="flex items-center gap-2">
                                <p className='text-[black] text-[14px]'>Month/Year</p>
                                <div className="relative">
                                    {/* <input
                                        type="month"
                                        onChange={(e) => {
                                            setDate(e.target.value);
                                        }}

                                    /> */}

                                    <input
                                        type="month"
                                        value={selectedMonth}
                                        onChange={e => setSelectedMonth(e.target.value)}
                                        className=" absolute left-[0px] top-0 w-full opacity-0 cursor-pointer h-full z-10"
                                    />
                                    <button
                                        className="flex items-center gap-[7px] py-4 px-2 rounded-[10px] bg-[#F2F2F2] text-[#7A7A7A] text-[12px] font-[800]"
                                        type="button"
                                    >
                                        <svg
                                            width="15"
                                            height="16"
                                            viewBox="0 0 15 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.875 12.375H3.125V5.5H11.875M10 1.125V2.375H5V1.125H3.75V2.375H3.125C2.43125 2.375 1.875 2.93125 1.875 3.625V12.375C1.875 12.7065 2.0067 13.0245 2.24112 13.2589C2.47554 13.4933 2.79348 13.625 3.125 13.625H11.875C12.2065 13.625 12.5245 13.4933 12.7589 13.2589C12.9933 13.0245 13.125 12.7065 13.125 12.375V3.625C13.125 3.29348 12.9933 2.97554 12.7589 2.74112C12.5245 2.5067 12.2065 2.375 11.875 2.375H11.25V1.125M10.625 8H7.5V11.125H10.625V8Z"
                                                fill="#2EABE3"
                                            />
                                        </svg>
                                        {selectedMonth ? selectedMonth : 'Select'}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-wrap items-center gap-3 relative">




                    </div>
                </div>

                {isLoading ? (

                    <Loader />
                ) : (
                    <div className="py-6 overflow-auto border-t border-b border-[#E3E3E3] mt-6 mb-6 flex flex-col gap-6">
                        <div className="relative">
                            <div className="overflow-x-auto w-full">
                                <table className="checkin-table">

                                    <thead>
                                        <tr>
                                            <th className='text-left'>Date</th>
                                            <th className='text-center'># Checkins</th>
                                            <th className='text-center'># Checkouts</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {dailyReport.length > 0 ?
                                            dailyReport.map(day => (
                                                <tr key={day.date}>
                                                    <td className='text-left'>
                                                        {moment(day.date).format(ONLY_DATE_FORMAT)}
                                                    </td>
                                                    <td className='text-center' >
                                                        <span onClick={() => {
                                                            if (day.checkInCount != 0) {
                                                                handleRowClick(day.date, '')
                                                            }

                                                        }} className={`md:h-[35px] h-[28px] mx-auto text-[12px] text-center md:w-[35px] w-[28px]  rounded-full flex items-center font-[700] bg-[#2EABE3] text-[white] justify-center cursor-pointer ${day.checkInCount != 0 ? "" : 'disable-span'} `}>{day.checkInCount}</span>
                                                    </td>
                                                    <td className='text-center'>
                                                        <span onClick={() => {
                                                            if (day.checkOutCount != 0) {
                                                                handleRowClick(day.date, '')
                                                            }

                                                        }} className={`md:h-[35px] h-[28px] mx-auto text-[12px] text-center md:w-[35px] w-[28px] rounded-full flex items-center font-[700] bg-[#2EABE3] text-[white] justify-center cursor-pointer ${day.checkOutCount != 0 ? "" : 'disable-span'} `}>{day.checkOutCount}</span>
                                                    </td>
                                                    {/* <td className='text-right'>
                                                        <button onClick={() => {
                                                            handleRowClick(day.date)

                                                        }} type="button">View Detials</button>
                                                    </td> */}

                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan='4'>
                                                    <div className=" overflow-hidden mt-6   flex flex-col gap-6 w-full"><p className="text-center text-[#2EABE3] md:text-[20px] text-[16px]">No Reservations Found</p></div>
                                                </td>
                                            </tr>
                                        }



                                    </tbody>
                                </table>








                            </div>

                        </div>
                    </div>
                )}
                <div className="mx-auto text-center w-fit">
                    {/* <Pagination totalPages={totalPages} currentPage={currentPage}
                    onPage={(page) => {
                        setCurrentPage(page)
                    }}
                    onNext={() => {
                        setCurrentPage(currentPage + 1)
                    }}
                    onPrev={() => {
                        setCurrentPage(currentPage - 1)
                    }} /> */}
                </div>
            </div>
        </>
    )
}

export default CheckInCheckOut
